import { WebStorageStateStore, InMemoryWebStorage, User } from "oidc-client-ts";
import { setCookie, removeCookie } from "typescript-cookie";

const oidcConfig = {
    authority: process.env.REACT_APP_AUTHORITY,
    client_id: process.env.REACT_APP_CLIENTID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    silent_redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    response_type: process.env.REACT_APP_RESPONSETYPE,
    scope: process.env.REACT_APP_SCOPE,
    automaticSilentRenew: false,
    userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
    onSigninCallback: (user: User) => {
        if (user) {
            console.log('set cookie');
            const tenant: any = user.profile.tenant;
            console.log(tenant);
            setCookie("tenant", tenant, {
                path: "",
                domain: process.env.REACT_APP_COOKIE_DOMAIN,
                expires: user.expires_at
            });
        }
    },
    onRemoveUser: () => {
        console.log('delete cookie');
        removeCookie("tenant", {
            path: "",
            domain: process.env.REACT_APP_COOKIE_DOMAIN
        });
    }
}

export default oidcConfig